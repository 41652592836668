<template>
  <van-tabs v-model:active="active" @change="onChange">
    <van-tab title="短信登录">
      <p style="margin:7px 15px;">
        <small>未注册手机号会自动注册</small>
      </p>
      <van-form @submit="onSubmitByCaptcha">
        <van-field
          v-model="phone"
          name="phone"
          label="手机号"
          placeholder="请输入"
        />
        <van-field
          v-model="captcha"
          name="captcha"
          center
          clearable
          label="验证码"
          placeholder="请输入"
          autocomplete="off"
          :formatter="inputChange"
        >
          <template #button>
            <van-button size="small" type="primary" @click="onSendCaptcha" :disabled="timeOut !== ''">
              <span v-if="timeOut">{{codetimer}}S后获取</span>
              <span v-else>发送验证码</span>
            </van-button>
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">确认登录</van-button>
        </div>
      </van-form>
    </van-tab>
    <van-tab title="密码登录">
      <van-form @submit="onSubmitByPassword" style="">
        <van-field
          v-model="phone"
          name="phone"
          label="手机号"
          placeholder="请输入"
        />
        <van-field
          v-model="password"
          type="password"
          name="password"
          label="密码"
          placeholder="请输入"
        />
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">确认登录</van-button>
        </div>
      </van-form>
    </van-tab>
  </van-tabs>
  <rights />
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import Rights from '@/components/Rights.vue'
import { Toast, Dialog } from 'vant'

export default {
  components: {
    Rights
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const state = reactive({
      show: false,
      active: 0,
      phone: '',
      password: '',
      captcha: '',
      timeOut: '',
      codetimer: 180
    })
    const inputChange = (value) => {
      if (value.length > 6) value = value.slice(0, 4)
      return value
    }
    const onChange = (value) => {
      state.password = ''
      state.captcha = ''
    }
    const onClearCache = () => {
      const obj = Cookies.get()
      for (const item of Object.keys(obj)) {
        Cookies.remove(item)
      }
      Dialog.alert({
        title: '提示',
        message: '缓存已清理，请关闭页面后重新进入'
      }).then(() => {
      })
    }
    const onSendCaptcha = () => {
      if (state.timeOut) return
      post('/util.sendCaptcha', {
        phone: state.phone
      }).then(res => {
        if (res.code === 0) {
          Toast.success('短信已发送')
          setTimeOut()
        } else {
          Toast(res.msg)
        }
      })
    }
    const setTimeOut = () => {
      state.timeOut = setInterval(() => {
        if (state.codetimer > 1) {
          state.codetimer -= 1
        } else {
          state.codetimer = 180
          clearTimeout(state.timeOut)
          state.timeOut = ''
        }
      }, 1000)
    }
    const loginSuccess = ({ token, userId, roleId }) => {
      Toast.success()
      Cookies.set('sellerId', roleId, 1)
      Cookies.set('userId', userId, 1)
      Cookies.set('token', token, 1)
      useRouter.push({
        name: 'SellerIndex',
        query: {}
      })
    }
    const onSubmitByPassword = (values) => {
      console.log(values)
      post('/user.login', {
        phone: values.phone,
        password: values.password,
        type: 'PASSWORD',
        role: 'SELLER'
      }).then(res => {
        if (res.code === 0) {
          loginSuccess(res.data)
        } else {
          Toast(res.msg)
          if (res.code !== 99) {
            state.codetimer = 180
            clearTimeout(state.timeOut)
            state.timeOut = ''
          }
        }
      })
    }
    const onSubmitByCaptcha = (values) => {
      post('/user.login', {
        phone: values.phone,
        captcha: values.captcha,
        type: 'CAPTCHA',
        role: 'SELLER'
      }).then(res => {
        if (res.code === 0) {
          loginSuccess(res.data)
        } else {
          Toast(res.msg)
        }
      })
    }
    const init = () => {
      console.log(Cookies.get('client'))
      if (typeof Cookies.get('token') !== 'undefined') {
        useRouter.push({
          name: 'SellerIndex',
          query: {}
        })
      }
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmitByPassword,
      onSubmitByCaptcha,
      onSendCaptcha,
      onClearCache,
      onChange,
      setTimeOut,
      inputChange
    }
  }
}
</script>
